import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#344955",
        secondary: "#232F34",
        accent: "#F9AA33",
        error: "#B00020",
        warning: "#FFD600",
        info: "#2196f3",
        success: "#4caf50"
      }
    }
  }
});
